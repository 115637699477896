<template>
    <div class="flex m-10-0">
		<div v-if="isNoEmpty"></div>
		<div class="flex-1 bg-f h-610 p-0-20 rad-2"  v-for="(item,k) in smallitem" :key="k">
			<div class="h-70 l-h-74 flex start c-3 f-24 font-fm-ruizi" v-if="item"><i class="shu t-25 relative m-r-10"></i>{{item.discountname||"请选择专场"}}</div>
			<div class="h-240 m-b-20" @click="gobiglist(item.id)">
				<div href="" v-if="item"><img class="w100 h-240 " :src="item.imageurl" alt=""></div>
			</div>
			
			<div class="flex"  >
				<div v-for="its in item.glist" @click="godetail(its.id)" :key="its.id">
					<div >
						<img class="w-170 h-170 m-b-15" :src="its.imageurl||require('@/assets/img/mr1.png')" alt="">
						<p class="c-3 f-14 ell m-b-10"> {{its.goodsname}}</p>
						<p class="c-6 m-b-15 h-14 f-12">{{its.spec}}</p>
						<!-- <p class="c-ff3 bold" v-if="$store.state.app.token"><span class="f-12">￥</span>{{its.price}}<span class="f-12"></span> </p> -->
						<p class="c-ff3 bold" v-if="$store.state.app.token"> 
							<price v-if="$store.state.app.showprice!=1" :price="its.batchInfoList&&its.batchInfoList[0]?its.batchInfoList[0].price:its.price" :size="20"/> 
						</p>
						<p class="c-ff3 " v-else>价格登录后可见</p>
					</div>
				</div>
				<div v-if="showmoban&&item.glist&&!item.glist.length" v-for="its in smallitem.length==1?5:3">
					<img class="w-170 h-170 m-b-15" src="@/assets/img/mr.png" alt="">
					<p class="c-3 f-14 ell m-b-10">通用名称</p>
					<p class="c-6 m-b-15">商品产品规格</p>
					<p class="c-ff3 "><span class="f-12">价格登录后可见</span> </p>
				</div>
			</div>
		</div>
		
		<!-- <div class="flex-1 bg-f h-610 p-0-20 rad-2" >
			<div class="h-70 l-h-74 flex start c-3 f-24 font-fm-ruizi" v-if="smallitem[1]"><i class="shu t-25 relative m-r-10"></i>{{smallitem[1].discountname||"请选择专场"}}</div>
			<div class="h-240 m-b-20" @click="gobiglist(1)">
				<div href="" v-if="smallitem[1]"><img class="w100 h-240 " :src="smallitem[1].imageurl" alt=""></div>
			</div>
			<div v-if="isNoEmpty"></div>
			<div class="flex"  >
				<div v-for="its in rightlist" @click="godetail(its.id)">
					<div >
						<img class="w-170 h-170 m-b-15" :src="its.imageurl||require('@/assets/img/mr1.png')" alt="">
						<p class="c-3 f-14 ell m-b-10"> {{its.goodsname}}</p>
						<p class="c-6 m-b-15 h-14 f-12">{{its.spec}}</p>
						<p class="c-ff3 bold" v-if="$store.state.app.token"> <price :price="its.price" :size="20"/> </p>
						<p class="c-ff3 " v-else>价格登录后可见</p>
					</div>
				</div>
				<div v-if="!rightlist.length" v-for="its in 3">
					<img class="w-170 h-170 m-b-15" src="@/assets/img/mr.png" alt="">
					<p class="c-3 f-14 ell m-b-10">通用名称</p>
					<p class="c-6 m-b-15">商品产品规格</p>
					<p class="c-ff3 "><span class="f-12">价格登录后可见</span> </p>
				</div>
			</div>
		</div> -->
    </div>
</template>

<script>
export default {
    name: 'smallactivity',
	data() {
		return{
			leftlist:[],
			rightlist:[],
			smallitem:[],
			count:0,
			showmoban:false,

		}
	},
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			
			console.log('formData',e)
		}
	},
	
	computed: {
		isNoEmpty() {
			const {formData} = this;
			var  getTenantid=sessionStorage.getItem('getTenantid')
			if(getTenantid) {
				getTenantid=JSON.parse(getTenantid)

			}
			console.log('$store',getTenantid)
			this.getselectSpecialByPid()
			if(this.$route.path=='/edit'){this.showmoban=true}
			return !!(formData.imgList && formData.imgList.length > 0 && formData.imgList[0].imgUrl);
		}
	},
	methods:{
		getselectSpecialByPid(){
			const {formData} = this;
			let smallitem=formData.smallitem
			let nlist=[]
			console.log(222222222222,formData)
			this.requset('/b2bgoods/selectSpecialByPid',{typeid:2}).then((resp)=> {
				console.log('selectSpecialByPid',resp)
				// resp.data=[{}]
				resp.data.map((res,k)=>{
					res.discountname=res.specialname
					res['glist']=[]
					// if(res.id==formData.SelectWidget)
					if(k==0) smallitem[0]=res,formData.SelectWidget=res.id
					if(k==1) smallitem[1]=res,formData.SelectWidget1=res.id
					// if(res.id==formData.SelectWidget1)smallitem[1]=res
					
				})
				console.log('selectSpecialByPid',resp)
				this.smallitem=resp.data
				this.getquerygoodsbyspecial()
				
			});
		
		},
		
		
		// 获取小专场  
		getquerygoodsbyspecial(){
			const {formData} = this;
			var length=this.smallitem.length
			console.log('获取小专场',this.smallitem,formData)
			if(formData.SelectWidget){
				this.requset('/b2bgoods/querycategorygoods',{specialid:formData.SelectWidget,isloadbatch: true,}).then((res)=> {
					console.log('小转场',res)
					if(res.data){
						
						this.smallitem[0]['glist']=res.data.slice(0,length==1?5:3)
						this.glist=res.data.slice(0,3)
					}
					
				});
			}

		
			if(formData.SelectWidget1){
				this.requset('/b2bgoods/querycategorygoods',{specialid:formData.SelectWidget1,isloadbatch: true,}).then((res)=> {
					if(res.data){
						this.rightlist=res.data.slice(0,length==1?5:3)
						if(this.smallitem[1])this.smallitem[1]['glist']=res.data.slice(0,length==1?5:3)
						// console.log('小转场1',this.List)
					}
					
				});
			}
			this.count++
		},
		
		
		// // 页面跳转到详情
		godetail: function godetail(id) {
			console.log('godetail',id)
			sessionStorage.setItem('pageno',this.pageno);
			if(location.href.indexOf('edit')!=-1)return
			document.documentElement.scrollTop=0
			this.$router.push({path:'/detail',query:{goodsid:id}})
		},
		
		gobiglist(id){
			console.log('formData',this.formData)
			if(location.href.indexOf('edit')==-1){
				document.documentElement.scrollTop=0
				// let id
				// k==0?id=this.formData.SelectWidget:id=this.formData.SelectWidget1
				console.log('formData',id)
				this.$router.push({path:'/bigsite',query:{id:id}})
			}
			
		}

	
	}
};
</script>
<style>
	
</style>
<style module>
    .box {
        display: flex;
    }
	.w48{width: 48%;height: 610px;background: #fff;}
	
</style>
